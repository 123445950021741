<template>
  <section>
    <b-card>
      <template #header>
        <div class="d-flex align-items-center">
          <span class="text-primary">Newsletter </span>
          <b-breadcrumb class="ml-2">
            <b-breadcrumb-item>
              <feather-icon
                icon="HomeIcon"
                scale="1.25"
                shift-v="1.25"
              ></feather-icon>
            </b-breadcrumb-item>
            <b-breadcrumb-item active> Liste destinatari</b-breadcrumb-item>
          </b-breadcrumb>
        </div>

        <div>
          <b-button variant="primary"> Crea Nuova Lista </b-button>
        </div>
      </template>

      <b-row class="mt-2">
        <b-col md="9"> </b-col>

        <b-col md="3">
          <div class="d-flex align-items-center mb-2">
            <span class="mr-1">Cerca</span>
            <b-form-input type="text" v-model="filter" size="md" />
          </div>
        </b-col>
      </b-row>

      <b-table
        :fields="fields"
        :items="recipientProvider"
        :filter="filter"
        :busy="loading"
        striped
        show-empty
        responsive
        @sort-changed="sortTable"
      >
        <template #table-busy>
          <div class="text-center text-primary-light my-2">
            <b-spinner class="align-middle mr-2"></b-spinner>
            <strong>Caricamento dati delle liste...</strong>
          </div>
        </template>

        <template #empty>
          <h5 class="text-primary-light text-center my-2">Non ci sono liste</h5>
        </template>

        <template #emptyfiltered>
          <h5 class="text-primary-light text-center my-2">Non sono state trovate liste</h5>
        </template>
  
        <template #cell(count_email)="data">
          {{ data.item.count_email }} E-mail
        </template>
  
        <template #head(opzioni)>
          <span></span>
        </template>
        <template #cell(opzioni)="data">
          <feather-icon
            icon="FileTextIcon"
            class="cursor-pointer text-primary-light mr-2"
            size="20"
            @click="
              $router.push({
                name: 'newsletter_recipient_details',
                params: { id: data.item.id_lista },
              })
            "
            v-b-tooltip.hover.top.v-primary-light="'Dettagli'"
          />
          <feather-icon
            icon="Trash2Icon"
            class="cursor-pointer text-danger"
            size="20"
            v-b-tooltip.hover.top.v-danger="'Elimina'"
            @click="deleteList(data.item.id_lista)"
          />
        </template>
      </b-table>
    </b-card>
  </section>
</template>

<script>
import LayoutVertical from "@core/layouts/layout-vertical/LayoutVertical.vue";
import {
  BButton,
  BRow,
  BCol,
  BBreadcrumb,
  BBreadcrumbItem,
  BCard,
  BTable,
  BFormInput,
  VBTooltip,
} from "bootstrap-vue";

import { Requests } from "@/api";

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BBreadcrumb,
    BBreadcrumbItem,
    BCard,
    BTable,
    BFormInput,
    LayoutVertical,
  },

  directives: {
    "b-tooltip": VBTooltip,
  },

  data: () => ({
    fields: [
      {
        key: "id_list",
        label: "id",
        sortable: true,
      },
      {
        key: "list_name",
        label: "nome lista",
        sortable: true,
      },
      {
        key: "count_email",
        label: "indirizzi",
        sortable: true,
      },
      {
        key: "creation_date",
        label: "data_creazione",
        sortable: true,
      },
      {
        label: "note",
        key: "list_notes",
        sortable: true,
      },
      {
        key: "opzioni",
        sortable: false,
      },
    ],

    filter: null,
    recipients: [],
    sortBy: 'id_list',
    sortDesc: false,

    //Helpers

    loading: false,
    currentPage: 1,
    perPage: 15,
  }),

  methods: {
    async getRecipientList(page) {
      this.loading = true;

      page = page ?? this.currentPage;

      let request_data = new FormData();

      request_data.append("offset", (page - 1) * this.perPage);
      request_data.append("limit", this.perPage);
      request_data.append("filter", this.filter);
      request_data.append("sortBy", this.sortBy);
      request_data.append("sortDesc", this.sortDesc);

      try {
        const response = await Requests.getRecipientList(request_data);
        this.recipients = response;
      } catch (err) {
        console.debug(err);
      }

      this.loading = false;
    },

    async deleteList(list_id) {
      this.$swal.fire({
        title: "Elimina Lista",
        text: "L'azione è irreversibile. Vuoi procedere?",
        showCancelButton: true,
        cancelButtonText: "Annulla",
        confirmButtonText: "Elimina",
        customClass: {
            title: "text-primary-light",
            confirmButton: 'btn btn-danger mr-2',
            cancelButton: 'btn btn-secondary'
        }
      })
    },

    async recipientProvider() {
      await this.getRecipientList();
      return this.recipients.data;
    },

    async sortTable(ctx) {
      this.sortBy = ctx.sortBy;
      this.sortDesc = ctx.sortDesc;
    },
  },
};
</script>
